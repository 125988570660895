import React, { useCallback, useEffect, useState } from 'react'

import { Alert, Box, useTheme } from '@mui/material'
import {
	VictoryChart,
	VictoryAxis,
	VictoryArea,
	VictoryScatter,
	VictoryTooltip,
	VictoryVoronoiContainer,
} from 'victory'

function NoData() {
	return <Alert severity="warning">User needs to start tracking bowel movements to see results!</Alert>
}

function Chart({ xAxis, yAxis, data }) {
	const showScatter = data.length < 10
	const strokeWidth = data.length < 40 ? 3 : 2

	const palette = useTheme().palette
	const isDarkMode = palette.mode === 'dark'

	const [width, setWidth] = useState(0)

	// We need this graphRef woraround if we want to keep a fixed height and have the width
	// adjust to the window viewport. This a common issue with svg charts.
	// Referefence for the workaround: https://github.com/FormidableLabs/victory/issues/396
	const graphRef = useCallback((node) => {
		if (node !== null) {
			setWidth(node.getBoundingClientRect().width)
		}
	}, [])

	const updateWidth = (ev) => {
		setWidth(ev.target.innerWidth)
	}

	useEffect(() => {
		window.addEventListener('resize', updateWidth)

		return () => {
			window.removeEventListener('resize', updateWidth)
		}
	}, [])

	if (data.length === 0) return <NoData />

	return (
		<Box sx={{ width: '100%' }} ref={graphRef}>
			<VictoryChart
				height={300}
				width={width}
				domainPadding={{ x: [10, 0], y: [0, 12] }}
				padding={{ top: 20, left: 50, bottom: 40, right: 50 }}
				containerComponent={<VictoryVoronoiContainer responsive={false} />}
			>
				<VictoryAxis
					fixLabelOverlap
					dependentAxis
					tickValues={yAxis}
					style={{
						tickLabels: {
							fill: isDarkMode ? palette.primary[50] : palette.secondary[300],
						},
						axis: {
							stroke: palette.primary[300],
							strokeWidth: 0,
						},
					}}
				/>
				<VictoryAxis
					fixLabelOverlap
					tickValues={xAxis}
					maxDomain={7}
					style={{
						tickLabels: {
							fill: isDarkMode ? palette.primary[50] : palette.secondary[300],
						},
						grid: {
							stroke: palette.primary[50],
						},
						axis: {
							stroke: palette.primary[100],
							strokeDasharray: '5, 10',
							strokeWidth: 2,
						},
					}}
				/>
				<VictoryArea
					data={data}
					interpolation="natural"
					style={{
						data: {
							stroke: palette.primary[500],
							strokeLinecap: 'round',
							strokeWidth,
							opacity: 90,
							fill: palette.primary[500],
							fillOpacity: 0.1,
						},
					}}
				/>
				<VictoryScatter
					data={data}
					size={showScatter ? 4.5 : 0}
					style={{ data: { fill: 'white' } }}
					labels={({ datum }) => {
						return `${xAxis[datum.x - 1]}\n ${datum.y.toFixed(2)}`
					}}
					labelComponent={<VictoryTooltip />}
				/>
				<VictoryScatter
					data={data}
					size={showScatter ? 3.5 : 0}
					style={{ data: { fill: palette.primary[500] } }}
				/>
			</VictoryChart>
		</Box>
	)
}

export default Chart
