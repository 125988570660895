import React, { useEffect, useState, useMemo } from 'react'
import { getBowelAnalysis } from '../../../apis/bowel'
import { Alert, Box, CircularProgress, Container, Grid } from '@mui/material'
import { ChartWrapper } from './ChartWrapper'
import ToggleButton from '@mui/material/ToggleButton'
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup'

export default function StoolTrendsContent() {
	const [analysis, setAnalysis] = useState({
		days: [],
		bowel: [],
	})
	const [days, setDays] = useState(7)
	const [loading, setLoading] = useState(true)

	const fetchData = () =>
		getBowelAnalysis()
			.then(({ data: r }) => {
				setAnalysis(r.data)
			})
			.catch(console.error)
			.finally(() => setLoading(false))

	useEffect(() => {
		fetchData()
	}, [])

	const data = useMemo(() => {
		return {
			days: analysis?.days?.slice(0, days).reverse() || [],
			bowel: analysis?.bowel?.slice(0, days).reverse() || [],
		}
	}, [analysis, days])

	if (loading) {
		return (
			<Box height={500} display="flex" justifyContent="center" alignItems="center">
				<CircularProgress size={80} />
			</Box>
		)
	}

	return (
		<Container maxWidth="xl">
			<Box display="flex" justifyContent="center" pt={1}>
				<DaySelectionToggle setDays={setDays} />
			</Box>
			<Grid container spacing={2} mt={1}>
				<Grid item xs={12}>
					<ChartWrapper analysis={data} days={days} characteristic="bristol" title="Stool Type" />
				</Grid>
				<Grid item xs={12}>
					<ChartWrapper analysis={data} days={days} characteristic="consistency" />
				</Grid>
				<Grid item xs={12}>
					<ChartWrapper analysis={data} days={days} characteristic="fragmentation" />
				</Grid>
				<Grid item xs={12}>
					<ChartWrapper analysis={data} days={days} characteristic="volume" />
				</Grid>
				<Grid item xs={12}>
					<ChartWrapper analysis={data} days={days} characteristic="edge_fuzziness" />
				</Grid>
				<Grid item xs={12}>
					<ChartWrapper analysis={data} days={days} characteristic="frequency" />
				</Grid>
			</Grid>
		</Container>
	)
}

function DaySelectionToggle({ setDays }) {
	const [toggleValue, setToggleValue] = useState(7)

	const handleChange = (event, days) => {
		if (days) {
			setDays(days)
			setToggleValue(days)
		}
	}
	const presets = [
		{ label: '1w', days: 7 },
		{ label: '2w', days: 14 },
		{ label: '1m', days: 30 },
		{ label: '3m', days: 90 },
		{ label: '1y', days: 365 },
	]

	return (
		<ToggleButtonGroup
			value={toggleValue}
			exclusive
			onChange={handleChange}
			aria-label="number of days selection"
			color="primary"
		>
			{presets.map((p) => {
				return (
					<ToggleButton key={p.label} value={p.days}>
						{p.label}
					</ToggleButton>
				)
			})}
		</ToggleButtonGroup>
	)
}
