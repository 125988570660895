import React from 'react'
import { Card, CardContent, CardHeader } from '@mui/material'
import moment from 'moment/moment'
import { useMemo } from 'react'
import Chart from './Chart'

export function ChartWrapper({ analysis, characteristic, title, days }) {
	let yAxis = []
	const xAxis = []

	const isForFrequency = characteristic === 'frequency'
	if (!isForFrequency) {
		if (characteristic === 'bristol') {
			yAxis = [1, 3, 5, 7]
		} else {
			yAxis = [0, 20, 40, 60, 80, 100]
		}
	}

	const data = useMemo(() => {
		return analysis.bowel?.map((bowel, idx) => {
			xAxis.push(moment(bowel.day).format('MMM D '))
			const d = {
				x: idx + 1,
				y: 0,
			}

			if (isForFrequency) {
				d.y = bowel.count || 0
			} else {
				d.y = bowel.mean.ai[characteristic] || bowel.mean[characteristic] || 0
			}

			return d
		})
	}, [analysis, characteristic, xAxis])

	const getTitle = () => {
		if (title) return title
		const words = characteristic.split('_').map((w) => `${w[0].toUpperCase()}${w.substring(1)}`)
		return words.join(' ')
	}

	return (
		<Card>
			<CardHeader title={getTitle()} subheader={`Most Recent ${days} Days`} />
			<CardContent sx={{ padding: 0 }}>
				<Chart xAxis={xAxis} yAxis={yAxis} data={data || []} />
			</CardContent>
		</Card>
	)
}
